<template>
  <div id="technology" class="body">
    <Top></Top>

    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>技術分野</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index">Home</router-link></li>
                <li class="active">会社案内</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="container pb-1">

        <div class="row">
          <div class="col-lg-3 order-2 order-lg-1 mt-4 mt-lg-0 pt-2">
            <aside class="sidebar">
              <h5 class="font-weight-bold">Categories</h5>
              <ul class="nav nav-list flex-column mb-0">
                <li class="nav-item">
                  <router-link class="nav-link active" to="#">会社案内</router-link>
                  <ul>
                    <li class="nav-item"><router-link class="nav-link " to="/message">代表挨拶</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/philosophy">経営理念</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/history">会社沿革</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/business">事業案内</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/profile">会社概要</router-link></li>
                    <li class="nav-item"><router-link class="nav-link active " to="/technology">技術分野</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/access">アクセス</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/privacy">個人情報保護方針</router-link></li>
                  </ul>
                </li>
                <li class="nav-item"></li>
              </ul>

            </aside>
          </div>
          <div class="col-lg-9 order-1 order-lg-2">
            <div class="row">
              <div class="col">
                <div class="overflow-hidden mb-3">
                  <h2 class="text-color-dark font-weight-normal text-8 mb-0 appear-animation text-font-en" data-appear-animation="fadeIn"  data-appear-animation-delay="100">Technology</h2>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="overflow-hidden mb-5">

                  <table class="table table-striped table-bordered mb-5 appear-animation" data-appear-animation="fadeIn"  data-appear-animation-delay="100">
                    <tbody>
                    <tr>
                      <th class="w-25" scope="row">OS</th>
                      <td>WINDOWS、iOS、Android、UNIX、LINUX、DOS、ﾘｱﾙﾀｲﾑOS(ITRON、ARM7)、汎用機(ACOS)</td>
                    </tr>
                    <tr>
                      <th scope="row">データベース</th>
                      <td>Oracle、SQLServe、DB2、MySql、PostgreSQL、Ms-Access</td>
                    </tr>
                    <tr>
                      <th scope="row">開発言語</th>
                      <td>Java (JSP、EJB、J2ME等)、.NET(ASP.NET、VB.NET、C#.NET等)、
                        Unity、C、C++ 、VisualC++ 、VisualBasic、Cobol、
                        JavaScript、VBScript、ASP、Perl、PHP、Python、
                        XML、UML、PL/SQL</td>
                    </tr>
                    <tr>
                      <th scope="row">プロトコル</th>
                      <td>HTTP、TCP/IP、IPX、NetBEU</td>
                    </tr>
                    <tr>
                      <th scope="row">APサーバ</th>
                      <td>Tomcat、Weblogic、Webshpere、InterStage、JRun、JServer</td>
                    </tr>
                    </tbody>
                  </table>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="feature-box feature-box-style-6 p-2">
                        <div class="feature-box-icon">
                          <i class="far fa-life-ring"></i>
                        </div>
                        <div class="feature-box-info">
                          <h4 class="mb-2">ご提案</h4>
                          <p class="mb-0">お客様のご希望に応じシステム開発の多様なフェーズに対応し、必要な期間、必要なIT人材をご提案致します。</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>



    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "technology",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>