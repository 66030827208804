<template>
  <div id="philosophy" class="body">
    <Top></Top>


    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>代表挨拶</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index">Home</router-link></li>
                <li class="active">会社案内</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="container pb-1">
        <div class="row mt-3 mb-5">

          <div class="col-lg-12 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="400">
            <div class="row">
              <div class="col-sm-10 col-md-7 col-lg-12 mb-lg-0 ">
                <div class="overflow-hidden mb-2">
                  <!--<p class="mb-0 gradient-text-color text-5 " >代表挨拶</h4>-->
                  <p class="text-8 text-black text-font-en">Top Message</p>
                </div>


                <div>
                  <ul class="process-vertical text-color-dark">
                    イノソースは、革新的なソリューションと卓越したサービスを提供する、国際的な企業です。

私たちは、幅広い業界とクライアントに対して、高品質なビジネスプロセスアウトソーシングと人材ソリューションを提供しています。当社の専門家チームは、効率性を向上させ、生産性を最大化するための最新のテクノロジーや戦略を活用しています。

イノソースの強みは、優れたプロジェクト管理能力と優秀な人材の組み合わせにあります。我々はクライアントのニーズに応じて柔軟に対応し、優れた成果を実現するために最善の解決策を提供します。

私たちは常に変化するビジネス環境に適応し、クライアントの成功を支えるために努力しています。イノソースは、革新的なアプローチと協力関係を築きながら、クライアントのビジネス目標の達成に貢献することをお約束します。

イノソースは、お客様とのパートナーシップを大切にし、持続可能な成長と相互の成功を追求しています。私たちは常に高い品質と信頼性を提供し、お客様の期待に応えることを使命としています。

私たちはイノベーションを重視し、最高のサービスと解決策を提供するために努力し続けます。イノソースと共に、ビジネスの新たな可能性を探索し、成長と成功を実現しましょう。
                    <br><br>
                    <p class="alternative-font-3 right text-color-dark">代表取締役　　 顧　楽諾</p>
                  </ul>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>


    </div>



    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";
export default {
  name: "message",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>