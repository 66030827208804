<template>
  <div id="history" class="body">
    <Top></Top>

    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>会社沿革</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index">Home</router-link></li>
                <li class="active">会社案内</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="container">
        <div class="col-sm-10 col-md-7 col-lg-12 mb-lg-0 appear-animation"  data-appear-animation="fadeIn" data-appear-animation-delay="400">
          <div class="overflow-hidden mb-2">
            <p class="text-8 text-black text-font-en">History</p>
          </div>
        </div>
        <div class="dt-history lines text-3 text-color-dark appear-animation"  data-appear-animation="fadeIn" data-appear-animation-delay="400">
          <!--
          <dl class="c-list-col__item ">
            <dt>2019.7</dt>
            <dd>一般労働者派遣新規申請</dd>
          </dl>-->
          <dl class="c-list-col__item ">
            <dt>2023.7</dt>
            <dd>長崎市長崎県にInnoSource株式会社設立</dd>
          </dl>
        </div>

      </div>

    </div>



    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "history",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>