<template>
  <div id="access" class="body">
    <Top></Top>


    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>アクセス</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index.html">Home</router-link></li>
                <li class="active">会社案内</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="container py-4">

        <div class="row mb-5">
          <div class="col-lg-3 order-2 order-lg-1 mt-4 mt-lg-0 pt-2  appear-animation "  data-appear-animation="fadeIn" data-appear-animation-delay="400" >
            <aside class="sidebar">
              <h5 class="font-weight-bold">Categories</h5>
              <ul class="nav nav-list flex-column mb-0">
                <li class="nav-item">
                  <router-link class="nav-link active" to="#">会社案内</router-link>
                  <ul>
                    <li class="nav-item"><router-link class="nav-link " to="/message">代表挨拶</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/philosophy">経営理念</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/history">会社沿革</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/business">事業案内</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/profile">会社概要</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/technology">技術分野</router-link></li>
                    <li class="nav-item"><router-link class="nav-link active " to="/access">アクセス</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/privacy">個人情報保護方針</router-link></li>
                  </ul>
                </li>
                <li class="nav-item"></li>
              </ul>

            </aside>
          </div>

          <div class="col-lg-0 offset-lg-1 order-1 order-lg-2 ml-0 mb-4  appear-animation "  data-appear-animation="fadeIn" data-appear-animation-delay="400" >

            <h2 class="pt-0 text-black text-10 text-font-en">Access</h2>
            <ul class="list list-icons list-icons-style-3 mt-2">
              <li><i class="fas fa-map-marker-alt top-6"></i> <strong>住所:</strong> <br>
                〒850－0035 長崎県長崎市元船町9-18 2階</li>
              <li><i class="fas fa-subway top-6"></i> <strong>電車:</strong><br>
                ・長崎電軌本線 / 五島町駅 徒歩3分<br>
                ・長崎電軌本線 / 大波止駅 徒歩4分<br>
                ・西九州新幹線 / 長崎駅 徒歩10分<br></li>
              <li><i class="fas fa-phone top-6"></i> <strong>Email:</strong><br>
                info@innosource.co.jp<br>
                <i class="far fa-clock top-6"></i>&nbsp;受付時間：平日10:00～12:00 13:00～17:30<br>
                （土日、祝祭日、年末年始、弊社が定める定休日を除く）
              </li>
            </ul>

          </div>
        </div>

      </div>

      <div id="googlemaps-all" class="google-map map-style-1 mt-5 mb-0" style="height: 475px;">

      </div>
    </div>


    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "access",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>