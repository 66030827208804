<template>
  <div id="privacy" class="body">
    <Top></Top>


    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>個人情報保護方針</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index.html">Home</router-link></li>
                <li class="active">会社案内</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="container pb-1">

        <div class="row">
          <div class="col">
            <div class="overflow-hidden text-center mb-3">
              <h2 class="word-rotator slide font-weight-bold text-5 mb-0 appear-animation" data-appear-animation="maskUp">
                <span>私たちラフ・ストーンは、以下規定の 個人情報保護方針を遵守します。</span>
              </h2>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-5">

          <div class="policy col-lg-10 p-0">
            <section>
              <div class="featured-boxes featured-boxes-modern-style-5 featured-boxes-modern-style-primary">
                <div class="featured-box">
                  <div class="row" style="padding: 20px;">
                    <div class="col-left col-lg-4 text-center" style="color:#fff">
                      <h4 class="mt-0 mb-0 pt-0 heading-dark">個人情報<strong>保護方針</strong></h4>
                      <p class="text-dark">privacy policy</p>
                      <div class="divider divider-style-4 divider-icon-sm taller">
                        <i class="fas fa-chevron-down"></i>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 p-0 mb-2">
                          <img src="vfront/img/pmark.gif" width="100" height="100">
                        </div>
                        <div class="establish col-lg-12 text-1 mt-2 p-0 mb-4">
                          制定 2023年7月8日<br>
                          改定 2023年7月8日<br>
                          イノソース株式会社<br>
                          代表取締役 　顧　楽諾
                        </div>
                      </div>
                    </div>
                    <div class="col-right col-lg-8" style="color:#FFF;">
                      <p style="text-align: left;color:#FFF;">　当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関<span style="font-size: 14.079999923706055px;">する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向</span><span style="font-size: 14.079999923706055px;">、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。</span></p>
                      <p  style="color:#FFF;">１．個人情報は、システム開発業務、及びＥＣ事業における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。</p>
                      <p style="color:#FFF;">２．個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。</p>
                      <p style="color:#FFF;">３．個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、万一の際には速やかに是正措置を講じます。</p>
                      <p style="color:#FFF;">４．個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。</p>
                      <p style="color:#FFF;">５．個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。</p>
                      <p style="color:#FFF;">　本方針は、全ての従業者に配付して周知させるとともに、当社のホームページ、パンフレットなどに掲載することにより、いつでもどなたにも入手可能な措置を取るものとします。</p>
                      <p style="text-align: right;color:#FFF;">以上</p>

                      <div class="accordion" id="accordion">
                        <div class="card card-default">
                          <div class="card-header">
                            <h5 class="card-title m-0">
                              <router-link class="accordion-toggle text-dark" data-toggle="collapse" data-parent="#accordion" to="#collapse1One">
                                個人情報問合せ窓口
                              </router-link>
                            </h5>
                          </div>
                          <div id="collapse1One" class="collapse show">
                            <div class="conatct card-body">
                              <p class="mb-1">個人情報保護方針に関するお問合せにつきましては、下記窓口で受付けております。</p>
                              <p class="infomation">
                                <i class="far fa-caret-square-right fa-lg mr-1"></i>イノソース株式会社 　個人情報問合せ窓口<br>
                                <i class="far fa-caret-square-right fa-lg mr-1"></i>〒850－0035<br>
                                <i class="far fa-caret-square-right fa-lg mr-1"></i> 長崎県長崎市元船町9-18 2階<br>
                                <i class="far fa-caret-square-right fa-lg mr-1"></i>メールアドレス：info@innosource.co.jp
                              </p>
                              <p>
                                ※ 土・日曜日、祝日、年末年始、ゴールデンウィーク期間、
                                夏季休暇期間は翌営業日以降の対応とさせていただきます。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </section>
          </div>

        </div>

        <div class="row mt-3 mb-5">

          <div class="policy col-lg-10 p-0">
            <section>
              <div class="featured-boxes featured-boxes-modern-style-5 featured-boxes-modern-style-primary">
                <div class="featured-box">
                  <div class="row" style="padding: 20px; color:#FFF">
                    <div class="col-left col-lg-4 text-center">
                      <h4 class="mt-0 mb-0 pt-0 heading-dark">個人情報の取扱いについて</h4>
                      <p style="color:#fff;">privacy policy</p>
                      <div class="divider divider-style-4 divider-icon-sm taller">
                        <i class="fas fa-chevron-down"></i>
                      </div>
                    </div>
                    <div class="col-right col-lg-8" style="color:#fff;">
                      <p style="color:#fff;">１．当社が取り扱う個人情報の利用目的</p>
                      <p style="color:#fff;">（1）ご本人から直接書面によって取得する個人情報（ホームページや電子メール等によるものを含む）の利用目的取得に先立ち、ご本人に対し書面により明示します。</p>
                      <p style="color:#fff;">（2）前項以外の方法によって取得する個人情報の利用目的</p>
                      <table class="table table-bordered" style="color:#fff;">
                        <tbody>
                        <tr>
                          <th>分類</th>
                          <th>利用目的</th>
                        </tr>
                        <tr>
                          <td>購入者情報</td>
                          <td>ご注文の処理、商品の配送、お支払いの処理、新商品のご案内、お客様のご要望への対応のため</td>
                        </tr>
                        <tr>
                          <td>お取引先担当者様情報</td>
                          <td>発注内容確認のため（通信記録等）</td>
                        </tr>
                        <tr>
                          <td>業務の受託に伴い、協力会社からお預かりする技術者情報</td>
                          <td>システム開発業務などに技術者選定のため顧客に紹介するため</td>
                        </tr>
                        <tr>
                          <td>人材紹介会社から取得する個人情報</td>
                          <td>人材採用のため</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

        </div>

        <div class="row mt-3 mb-5">

          <div class="policy col-lg-10 p-0">
            <section>
              <div class="featured-boxes featured-boxes-modern-style-5 featured-boxes-modern-style-primary">
                <div class="featured-box">
                  <div class="row" style="padding: 20px;color:#FFF;">
                    <div class="col-left col-lg-4 text-center">
                      <h4 class="mt-0 mb-0 pt-0 heading-dark">開示対象個人情報に関する事項の周知</h4>
                      <p style="color:#fff;">privacy policy</p>
                      <div class="divider divider-style-4 divider-icon-sm taller">
                        <i class="fas fa-chevron-down"></i>
                      </div>
                    </div>
                    <div class="col-right col-lg-8" style="color:#fff;">
                      <p style="color:#fff;">　当社で保有している開示対象個人情報に関して、ご本人様又はその代理人様からの利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の請求（以下、「開示等の請求」といいます）につきましては、以下の要領にて対応させていただきます。<br>
                        a) 事業者の名称</p>
                      <p style="padding-left: 30px;color:#fff;">イノソース株式会社</p>
                      <p style="color:#fff;">b) 個人情報の保護管理者</p>
                      <p style="padding-left: 30px;color: #fff;">管理者名：<br>
                        所属部署：ITソリューション事業部　マネージャー<br>
                        連絡先：info@innosource.co.jp</p>
                      <p  style="color:#fff;">c) 全ての開示対象個人情報の利用目的</p>
                      <table class="table table-bordered"  style="color:#fff;">
                        <tbody>
                        <tr>
                          <th style="width:40%">分類</th>
                          <th>利用目的</th>
                        </tr>
                        <tr>
                          <td>購入者情報</td>
                          <td>ご注文の処理、商品の配送、お支払いの処理、新商品のご案内、お客様のご要望への対応のため</td>
                        </tr>
                        <tr>
                          <td>お取引先担当者様情報</td>
                          <td>発注内容確認のため（通信記録等）</td>
                        </tr>
                        <tr>
                          <td>当社従業員情報</td>
                          <td>社員の人事労務管理、業務管理、健康管理、セキュリティ管理のため</td>
                        </tr>
                        <tr>
                          <td>当社への採用応募者情報</td>
                          <td>採用応募者への連絡と当社の採用業務管理のため</td>
                        </tr>
                        <tr>
                          <td>特定個人情報</td>
                          <td>番号法に定められた利用目的のため</td>
                        </tr>
                        </tbody>
                      </table>
                      <p style="color:#fff;">d) 開示対象個人情報の取扱いに関する苦情の申し出先</p>
                      <p style="padding-left: 30px;color:#fff;">イノソース株式会社 個人情報問合せ窓口<br>
                        〒850－0035 長崎県長崎市元船町9-18 2階<br>
                        メールアドレス：info@innosource.co.jp<br>
                        ※ 土・日曜日、祝日、年末年始、ゴールデンウィーク期間、夏季休暇期間は翌営業日以降の対応とさせていただきます。</p>
                      <p style="color:#fff;">e) 認定個人情報保護団体</p>
                      <p style="padding-left: 30px;color:#fff;">現在、当社が加盟する認定個人情報保護団体はありません。</p>
                      <p style="color:#fff;">f) 開示対象個人情報の開示等の求めに応じる手続き</p>
                      <p style="padding-left: 30px;color:#fff;">1) 開示等の求めの申し出先</p>
                      <p style="padding-left: 60px;color:#fff;">開示等のお求めは、上記個人情報問合せ係にお申し出ください。</p>
                      <p style="padding-left: 30px;color:#fff;">2) 開示等の求めに関するお手続き</p>
                      <p style="padding-left: 60px;color:#fff;">① お申し出受付け後、当社からご利用いただく所定の請求書様式「開示対象個人情報開示等請求書」を郵送いたします。<br>
                        ② ご記入いただいた請求書、代理人によるお求めの場合は代理人であることを確認する書類、手数料分の郵便為替（利用目的の通知並びに開示の請求の場合のみ）を上記個人情報問合せ係までご郵送ください。<br>
                        ③ 上記請求書を受領後、ご本人確認のため、当社に登録していただいている個人情報のうちご本人確認可能な2項目程度（例：電話番号と生年月日等）の情報をお問合せさせていただきます。<br>
                        ④ 回答は原則としてご本人に対して書面（封書郵送）にておこないます。</p>
                      <p style="padding-left: 30px;color:#fff;">3) 代理人によるお求めの場合、代理人であることを確認する資料</p>
                      <p style="padding-left: 60px;color:#fff;">開示等をお求めになる方が代理人様である場合は、代理人である事を証明する資料及び代理人様ご自身を証明する資料を同封してください。各資料に含まれる本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください。また各資料は個人番号を含まないものをお送りいただくか、全桁を墨塗り等の処理をしてください。<br>
                        ① 代理人である事を証明する資料<br>
                        　＜開示等の求めをすることにつき本人が委任した代理人様の場合＞<br>
                        　　本人の委任状（原本）<br>
                        　＜代理人様が未成年者の法定代理人の場合＞いずれかの写し<br>
                        　　戸籍謄本<br>
                        　　住民票（続柄の記載されたもの）<br>
                        　　その他法定代理権の確認ができる公的書類<br>
                        　＜代理人様が成年被後見人の法定代理人の場合＞いずれかの写し<br>
                        　　後見登記等に関する登記事項証明書<br>
                        　　その他法定代理権の確認ができる公的書類<br>
                        ② 代理人様ご自身を証明する資料<br>
                        　運転免許証<br>
                        　パスポート<br>
                        　健康保険の被保険者証<br>
                        　住民票<br>
                        　住民基本台帳カード</p>
                      <p style="padding-left: 30px;color:#fff;">4) 利用目的の通知または開示のお求めについての手数料</p>
                      <p style="padding-left: 60px;color:#fff;">1回のお求めにつき1000円<br>
                        （お送りいただく請求書等に郵便為替を同封していただきます。）</p>
                      <p style="text-align: right;color:#fff;">以上</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

        </div>

      </div>

    </div>


    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "privacy",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>