<template>
  <div id="recruit" class="body">
    <Top></Top>


    <div role="main" class="main">

      <div class="slider-container rev_slider_wrapper" style="height: 670px;">
        <div  class="slider rev_slider" data-version="5.4.8" data-plugin-revolution-slider data-plugin-options="{'delay': 7000, 'gridwidth': 1170, 'gridheight': 670, 'disableProgressBar': 'on', 'responsiveLevels': [4096,1200,992,500], 'parallax': { 'type': 'scroll', 'origo': 'enterpoint', 'speed': 1000, 'levels': [2,3,4,5,6,7,8,9,12,50], 'disable_onmobile': 'on' }, 'navigation' : {'arrows': { 'enable': false }, 'bullets': {'enable': true, 'style': 'bullets-style-1', 'h_align': 'center', 'v_align': 'bottom', 'space': 7, 'v_offset': 70, 'h_offset': 0}}}">
          <ul>
            <li class="" data-transition="fade">
              <img src="/vfront/img/plush-design.jpg"
                   alt=""
                   data-bgposition="center center"
                   data-bgfit="100%"
                   data-bgrepeat="no-repeat"
                   class="rev-slidebg">
            </li>
          </ul>
        </div>
      </div>

      <div class="container py-4">

        <div class="row">
          <div class="col-lg-5 mx-auto text-center mb-4">
            チャレンジできるフィールドを多数ご用意しております。<br>
            以下エントリーボタンより、皆様のご応募をお待ちしております。
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-lg-5 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
            <div class="card flip-card text-center rounded-0">
              <div class="flip-front p-2">
                <div class="flip-content my-2">
                  <strong class="font-weight-extra-bold text-color-dark line-height-3 text-4 mb-3 d-inline-block">システムエンジニア</strong>
                  <h4 class="font-weight-bold text-color-primary text-4">求む技術志向エンジニア！！</h4>
                  <p>要件定義・設計・コーディングなど上流工程から実装工程までスキルに応じて、幅広い業務にチャレンジしていただきます。スキルアップを目指せるプロジェクトに配属し、ご活躍していただきます。</p>
                  <p><button data-id="2" class="btn btn-open btn-light btn-modern text-4 text-color-dark font-weight-bold mx-auto">募集要項確認</button></p>
                </div>
              </div>
              <div class="flip-back d-flex align-items-center p-2">
                <div class="flip-content mx-auto my-2">


                  <button data-id="2" class="btn btn-open btn-light btn-modern text-4 text-color-dark font-weight-bold">募集要項確認</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-lg-5 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
            <div class="card flip-card text-center rounded-0">
              <div class="flip-front p-2">
                <div class="flip-content my-2">
                  <strong class="font-weight-extra-bold text-color-dark line-height-3 text-4 mb-3 d-inline-block">インフラエンジニア</strong>
                  <h4 class="font-weight-bold text-color-primary text-4">初心者歓迎！<br>求むチームリーダー！</h4>
                  <p>弊社が得意とするシステム開発をインフラエンジニアの観点からソフトウェアアーキテクチャの提案、新たなクラウドソリューションの導入を積極的に提案していただけるポジションとなります。</p>
                  <p><button data-id="3" class="btn btn-open btn-light btn-modern text-4 text-color-dark font-weight-bold mx-auto">募集要項確認</button></p>
                </div>
              </div>
              <div class="flip-back d-flex align-items-center p-2">
                <div class="flip-content mx-auto my-2">


                  <button data-id="3" class="btn btn-open btn-light btn-modern text-4 text-color-dark font-weight-bold">募集要項確認</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-lg-5 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
            <div class="card flip-card text-center rounded-0">
              <div class="flip-front p-2">
                <div class="flip-content my-2">
                  <strong class="font-weight-extra-bold text-color-dark line-height-3 text-4 mb-3 d-inline-block">ソフトウェア開発エンジニア</strong>
                  <h4 class="font-weight-bold text-color-primary text-4">高報酬案件を多数ご用意！！</h4>
                  <p>会社の持続発展に資するため、豊富なキャリアアップ研修コースを設けております。定期的に各種の研修カリキュラムを開講し、内容は語学、品質意識、管理手法、開発プロセス等多方面に渉り、能力研鑽を促す。</p>
                  <p><button data-id="4" class="btn btn-open btn-light btn-modern text-4 text-color-dark font-weight-bold mx-auto">募集要項確認</button></p>
                </div>
              </div>
              <div class="flip-back d-flex align-items-center p-2">
                <div class="flip-content mx-auto my-2">


                  <button data-id="4" class="btn btn-open btn-light btn-modern text-4 text-color-dark font-weight-bold">募集要項確認</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section class="section section-default mt-0 mb-5">
          <div class="container">
            <div class="block-area row appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="300">
              <ul class="list list-icons list-icons-style-3 list-primary">
                <li class="mb-0"><i class="far fa-envelope"></i><h4 class="mb-0">エントリーに関する同意</h4></li>
              </ul>
              <p class="mx-auto mb-3">以下の「<span class="font-weight-bold">個人情報の取り扱い</span>」をご確認していただき「同意して進む」ボタンよりお問い合わせください。</p>
              <div class="consent col-lg-12">


                    <pre>
このフォームで頂戴する個人情報の取り扱いについて
1. 事業者の氏名又は名称
イノソース株式会社

2. 個人情報保護管理者（若しくはその代理人）の氏名又は職名、
所属及び連絡先
所属部署：ITソリューション事業部　マネージャー
氏名 :
連絡先：メールアドレス：info@innosource.co.jp　

3. 個人情報の利用目的
採用応募者の個人情報は、弊社の人事採用活動（書類審査、
面接、評価、応募者への連絡等）のために利用いたします。

4. 個人情報の第三者への提供
業務担当者又は担当候補者の紹介のために、氏名、所属、
業務経歴書を、直接持参するか電子メール添付(パスワード付)で、
当社取引先に提供します。

5.個人情報取扱いの委託
当社は事業運営上、前項利用目的の範囲に限って個人情報を
外部に委託することがあります。この場合、個人情報保護水準の
高い委託先を選定し、個人 情報の適正管理・機密保持について
の契約を交わし、適切な管理を実施させます。

6. 個人情報の開示等の請求
ご本人様は、当社に対してご自身の個人情報の開示等に関して、
下記の当社問合わせ窓口に申し出ることができます。その際、
当社はお客様ご本人を確認させていただいたうえで、合理的な
期間内に対応いたします。

【お問合せ窓口】
イノソース株式会社 　個人情報問合せ窓口
〒850-0035長崎県長崎市元船町9-18 2階
メールアドレス：<span class="font-weight-bold text-font-en text-4"><a href="mailto:info@innosource.co.jp">info@innosource.co.jp</a></span>
※ 土・日曜日、祝日、年末年始、ゴールデンウィーク期間、
夏季休暇期間は	翌営業日以降の対応とさせていただきます。

7. 個人情報を提供されることの任意性について
ご本人様が当社に個人情報を提供されるかどうかは任意による
ものです。 ただし、必要な項目をいただけない場合、適切な対応が
できない場合があります。

                    </pre>
              </div>
            </div>
          </div>
        </section>

      </div>

    </div>


    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "recruit",
  components: {
    Top,
    Foot
  },
  mounted() {
    // Revolution Slider
    (function($) {

      'use strict';

      if ($.isFunction($.fn['themePluginRevolutionSlider'])) {

        $(function() {
          $('[data-plugin-revolution-slider]:not(.manual), .slider-container .slider:not(.manual)').each(function() {
            var $this = $(this),
                opts;

            var pluginOptions = theme.fn.getOptions($this.data('plugin-options'));
            if (pluginOptions)
              opts = pluginOptions;

            $this.themePluginRevolutionSlider(opts);
          });
        });

      }

    }).apply(this, [jQuery]);
  }
}
</script>

<style scoped>

</style>