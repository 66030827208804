import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import message from "@/views/message";
import philosophy from "@/views/philosophy";
import profile from "@/views/profile";
import historyPage from "@/views/history"
import business from "@/views/business";
import technology from "@/views/technology";
import access from "@/views/access";
import privacy from "@/views/privacy";
import service from "@/views/service";
import recruit from "@/views/recruit";
import partner from "@/views/partner";
import inquiries from "@/views/inquiries";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/index',
    name: 'index',
    component: Home
  },


  {
    path: '/profile',
    name: 'profile',
    component: profile
  },
  {
    path: '/message',
    name: 'message',
    component: message
  },
  {
    path: '/philosophy',
    name: 'philosophy',
    component: philosophy
  },
  {
    path: '/history',
    name: 'history',
    component: historyPage
  },
  {
    path: '/business',
    name: 'business',
    component: business
  },
  {
    path: '/technology',
    name: 'technology',
    component: technology
  },
  {
    path: '/access',
    name: 'access',
    component: access
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  },
  {
    path: '/service',
    name: 'service',
    component: service
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: recruit
  },
  {
    path: '/partner',
    name: 'partner',
    component: partner
  },
  {
    path: '/inquiries',
    name: 'inquiries',
    component: inquiries
  },


  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

// router.beforeEach((to, from,next) => {
//   // ...
//   // 返回 false 以取消导航
//   console.log(to);
//   if (to.query && to.query.a){
//     next();
//   }else {
//     next({
//       ...to,
//       query: {...to.query, a:1},
//       replace: true
//     });
//   }
//
// })

// router.afterEach((to, from) => {
//   if (to.query && to.query.a){
//
//   }
//   $("body").append(`<script id='ss' src="vfront/vendor/common/common.min.js"></script>></script>`)
//   //window.location.reload();
// })

export default router
