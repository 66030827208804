<template>
  <div id="top" class="body">
    <Top></Top>

    <div role="main" class="main">

      <div class="rev_slider_wrapper">
        <div id="revolutionSlider" class="slider rev_slider" data-version="5.4.8" data-plugin-revolution-slider data-plugin-options="{'delay': 6000, 'gridwidth': 1170, 'gridheight': 670, 'disableProgressBar': 'on', 'responsiveLevels': [4096,1200,992,500], 'parallax': { 'type': 'scroll', 'origo': 'enterpoint', 'speed': 1000, 'levels': [2,3,4,5,6,7,8,9,12,50], 'disable_onmobile': 'on' }, 'navigation' : {'arrows': { 'enable': false }, 'bullets': {'enable': true, 'style': 'bullets-style-1', 'h_align': 'center', 'v_align': 'bottom', 'space': 7, 'v_offset': 70, 'h_offset': 0}}}">
          <ul>
            <li data-transition="fade" data-masterspeed="700">
              <img src="vfront/img/stone-tower.jpg" alt="" style="width: 100%;">

              <div class="tp-caption font-weight-extra-bold text-color-light" data-frames='[{"delay":1900,"speed":1000,"frame":"0","from":"opacity:0;x:-50%;","to":"opacity:0.7;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                   data-x="center" data-hoffset="['75','75','75','115']" data-y="center" data-fontsize="['145','145','145','145']" data-lineheight="['150','150','150','150']"></div>

<!--              data-hoffset="['100','100','190','235']"-->
              <div class="tp-caption font-weight-extra-bold text-color-light text-shadow-light text-font-en" data-frames='[{"delay":2100,"speed":1000,"frame":"0","from":"opacity:0;x:-50%;","to":"opacity:0.9;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                   data-x="center"  data-y="center" data-fontsize="['100','100','100','100']" data-lineheight="['150','150','150','150']">InnoSource</div>

            </li>
          </ul>
        </div>
      </div>

<!--      <div class="slider-container rev_slider_wrapper" style="height: 670px;">-->
<!--        <div id="revolutionSlider" class="slider rev_slider" data-version="5.4.8" data-plugin-revolution-slider data-plugin-options="{'delay': 6000, 'gridwidth': 1170, 'gridheight': 670, 'disableProgressBar': 'on', 'responsiveLevels': [4096,1200,992,500], 'parallax': { 'type': 'scroll', 'origo': 'enterpoint', 'speed': 1000, 'levels': [2,3,4,5,6,7,8,9,12,50], 'disable_onmobile': 'on' }, 'navigation' : {'arrows': { 'enable': false }, 'bullets': {'enable': true, 'style': 'bullets-style-1', 'h_align': 'center', 'v_align': 'bottom', 'space': 7, 'v_offset': 70, 'h_offset': 0}}}">-->
<!--          <ul>-->
<!--            <li data-transition="fade" data-masterspeed="700">-->
<!--              <img src="vfront/img/stone-tower.jpg" alt="" data-bgposition="center center" data-bgfit="100%" data-bgrepeat="no-repeat" class="rev-slidebg">-->

<!--              <div class="tp-caption font-weight-extra-bold text-color-light" data-frames='[{"delay":1900,"speed":1000,"frame":"0","from":"opacity:0;x:-50%;","to":"opacity:0.7;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'-->
<!--                   data-x="center" data-hoffset="['75','75','75','115']" data-y="center" data-fontsize="['145','145','145','145']" data-lineheight="['150','150','150','150']"></div>-->

<!--              <div class="tp-caption font-weight-extra-bold text-color-light text-shadow-light text-font-en" data-frames='[{"delay":2100,"speed":1000,"frame":"0","from":"opacity:0;x:-50%;","to":"opacity:0.9;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'-->
<!--                   data-x="center" data-hoffset="['100','100','190','235']" data-y="center" data-fontsize="['100','100','100','100']" data-lineheight="['150','150','150','150']">InnoSource</div>-->

<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->

<!--      home-services-->
      <section class="home-services section bg-color-grey-scale-1 section-height-3 border-0 m-0">
        <div class="container py-5">
          <div class="row">
            <div class="col">
              <h2 class="font-weight-normal text-center text-6 text-color-dark pb-3 text-font-en">Our Services</h2>
            </div>
          </div>
          <div class="row mb-lg-3">
            <div class="col-lg-3 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
              <div class="feature-box feature-box-style-2">
                <div class="feature-box-icon">
                  <i class="fas fa-caret-square-right"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold mb-2">システム開発</h4>
                  <p>最先端の技術力を持つ技術者集団で、高い機動力とマネジメント力によって、お客様にとってより満足度の高いシステムをご提供しています。</p>
                  <ul class="list list-icons">
                  </ul>

                </div>
              </div>
            </div>

            <div class="col-lg-3 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
              <div class="feature-box feature-box-style-2">
                <div class="feature-box-icon">
                  <i class="fas fa-caret-square-right"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold mb-2">システム運用保守</h4>
                  <p>主に金融・流通・物流関係の業種で実績を積み重ねてきましたが、特に証券・金融業界を得意としており、豊富な実績を誇ります。</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 appear-animation" data-appear-animation="fadeInUpShorter">
              <div class="feature-box feature-box-style-2">
                <div class="feature-box-icon">
                  <i class="fas fa-caret-square-right"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold mb-2">ICT活用のBPO支援</h4>
                  <p>お客様の業務に沿って最適な業務フローを設計し、「システム」と「BPO」業務をセットで提供することにより、お客様の業務効率化、ミス軽減のための支援をします。</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
              <div class="feature-box feature-box-style-2">
                <div class="feature-box-icon">
                  <i class="fas fa-caret-square-right"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold mb-2">中国ソリューション</h4>
                  <p>中国の優れたITソリューションを発掘し、現地企業とコラボレーションすることで、日本市場にサービスを提供します。</p>
                </div>
              </div>
            </div>
          </div>
          
        </div>

      </section>

      <div class="container">


        <div class="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">
          <div class="col-md-4 mb-4 mb-md-0">
            <router-link to="/business">
                    <span class="thumb-info thumb-info-centered-info thumb-info-no-borders border-radius-0">
                        <span class="thumb-info-wrapper border-radius-0">
                            <img src="vfront/img/office.png" class="img-fluid border-radius-0" alt="">
                            <span class="thumb-info-title">
                                <span class="thumb-info-inner">ラフ・ストーンの事業を紹介</span>
                                <span class="thumb-info-type text-2">事業内容</span>
                            </span>
                        </span>
                    </span>
            </router-link>
          </div>
          <div class="col-md-8 pl-md-5">
            <h2 class="font-weight-normal text-6 mb-3"><strong class="font-weight-extra-bold mr-2">Business</strong> 事業概要</h2>
            <p class="text-4">InnoSource株式会社はITソリューション事業を軸とし幅広い領域で事業展開を行い、インターネットを介して人とメディアを繋ぐITソリューション企業です。</p>
            <p><router-link to="/business" class="read-more text-color-dark font-weight-semibold text-3">MORE<i class="fas fa-chevron-right text-1 ml-1"></i></router-link></p>
          </div>
        </div>

        <hr class="solid my-5">

        <div class="row align-items-center pb-5 appear-animation" data-appear-animation="fadeInRightShorter">
          <div class="col-md-8 pr-md-5 mb-5 mb-md-0">
            <h2 class="font-weight-normal text-6 mb-3"><strong class="font-weight-extra-bold mr-2">Recruiting </strong> 社員募集</h2>
            <p class="text-4">業務拡張に伴い、活力ある人材を募集しております。私たちのビジョンに共感し、ともに笑い、共に成長してくださる方、また、年齢に関係なくチャレンジできるフィールドを多数用意しています。ぜひ皆様のご応募をお待ちしております。採用に関するお問合わせは「<router-link class="" to="/recruit">エントリー・フォーム</router-link>」よりお気軽にご連絡ください。</p>
            <p><router-link to="/recruit" class="read-more text-color-dark font-weight-semibold text-3">MORE<i class="fas fa-chevron-right text-1 ml-1"></i></router-link></p>
          </div>
          <div class="col-md-4 px-5 px-md-3">
            <div class="portfolio-item">
              <router-link to="/recruit">
                    <span class="thumb-info thumb-info-centered-info thumb-info-no-borders border-radius-0">
                        <span class="thumb-info-wrapper border-radius-0">
                            <img src="vfront/img/recruit.png" class="img-fluid border-radius-0" alt="">
                            <span class="thumb-info-title">
                                <span class="thumb-info-inner">将来を担う活力ある社員を募集中！</span>
                                <span class="thumb-info-type text-2">採用情報</span>
                            </span>
                        </span>
                    </span>
              </router-link>
            </div>
          </div>
        </div>

      </div>



      <section id="owl-carousel-sec" class="section section-height-3 bg-light border-0 py-5 m-0">
        <div class="container">
          <div class="row">
          </div>
        </div>
      </section>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";
export default {
  name: 'Home',
  components: {
    Top,
    Foot
  },
  mounted() {
    this.$nextTick(()=>{
      console.log("home mounted");
      // window.onload = function() {
      //   // 実行したい処理
      //   $(".welcome,.welcomesabu").textAnimation({
      //     speed: 1000,
      //     delay: 200,
      //     left: 0,
      //     top: 0,
      //     scale: 4,
      //     rotateY: 200,
      //     rotateX: 0,
      //     translateZ: 200,
      //     // letterSpacing: 10,
      //     // easing: cubic - bezier(0.290, 0.350, 0.460, 1.200),
      //     // backgroundColor: transparent,
      //     // isRandomScale: false,
      //     // isRandomPosition: false,
      //     // isRandomRotateY: false,
      //     // isRandomRotateX: false,
      //     // isRandomTranslateZ: false,
      //     // isRandomSpeed: false,
      //     // isRandomDelay: false
      //   });
      // }

          // Revolution Slider
          (function($) {

            'use strict';

            if ($.isFunction($.fn['themePluginRevolutionSlider'])) {

              $(function() {
                $('[data-plugin-revolution-slider]:not(.manual), .slider-container .slider:not(.manual)').each(function() {
                  var $this = $(this),
                      opts;

                  var pluginOptions = theme.fn.getOptions($this.data('plugin-options'));
                  if (pluginOptions)
                    opts = pluginOptions;

                  $this.themePluginRevolutionSlider(opts);
                });
              });

            }

          }).apply(this, [jQuery]);


      // Animate
      (function($) {

        'use strict';

        if ($.isFunction($.fn['themePluginAnimate'])) {

          $(function() {
            $('[data-appear-animation]').each(function() {
              var $this = $(this),
                  opts;

              var pluginOptions = theme.fn.getOptions($this.data('plugin-options'));
              if (pluginOptions)
                opts = pluginOptions;

              $this.themePluginAnimate(opts);
            });
          });

        }

      }).apply(this, [jQuery]);

    })
  },

}
</script>
