<template>
  <div id="business" class="body">
    <Top></Top>


    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>事業内容</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index.html">Home</router-link></li>
                <li class="active">会社案内</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="container pb-1">

        <div class="row">
          <div class="col-lg-3 order-2 order-lg-1 mt-4 mt-lg-0 pt-2">
            <aside class="sidebar">
              <h5 class="font-weight-bold">Categories</h5>
              <ul class="nav nav-list flex-column mb-0">
                <li class="nav-item">
                  <router-link class="nav-link active" to="#">会社案内</router-link>
                  <ul>
                    <li class="nav-item"><router-link class="nav-link " to="/message">代表挨拶</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/philosophy">経営理念</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/history">会社沿革</router-link></li>
                    <li class="nav-item"><router-link class="nav-link active " to="/business">事業案内</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/profile">会社概要</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/technology">技術分野</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/access">アクセス</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/privacy">個人情報保護方針</router-link></li>
                  </ul>
                </li>
                <li class="nav-item"></li>
              </ul>

            </aside>
          </div>
          <div class="col-lg-9 order-1 order-lg-2 mb-5">
            <div class="row align-items-start">
              <div class="col-lg-6">
                <div class="pr-5">
                  <span class="d-block font-weight-light negative-ls-1 text-5 mb-1"><em class="text-2 opacity-8">ICT solutions</em></span>

                  <h2 class="font-weight-extra-bold text-color-dark negative-ls-3 line-height-4 text-5 mb-3"><em>ICTソリューション事業</em></h2>
                  <p class="lead mb-2 pb-2 text-3 text-indent-05">SESにより磨き抜かれた技術とナレッジにより、高い専門性を有するエキスパートが 製品、金融システム開発を行います。</p>
                  <div class="row">
                    <div class="col-md-12">
                      <ul class="list list-icons list-style-none pl-none mb-0 pb-0 pr-3">
                        <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="0" ><i class="fa fa-check text-primary text-4 mr-1"></i>受託サービス</li>
                        <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="300" ><i class="fa fa-check text-primary text-4 mr-1"></i>WEBサイト構築、運用、保守</li>
                        <li class="appear-animation text-2 text-color-primary mb-0 mb-md-3" data-appear-animation="fadeInUp" data-appear-animation-delay="1200"><i class="fa fa-check text-primary text-4 mr-1"></i>システム設計・開発・運用支援業務</li>
                        <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="900" ><i class="fa fa-check text-primary text-4 mr-1"></i>ECシステム構築</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="pr-5">
                  <span class="d-block font-weight-light negative-ls-1 text-5 mb-1"><em class="text-2 opacity-8">Offshore development business</em></span>
                  <h2 class="font-weight-extra-bold text-color-dark negative-ls-3 line-height-4 text-5 mb-3"><em>オフショア開発事業</em></h2>
                  <p class="lead mb-2 pb-2 text-3 text-indent-05">長年のオフショア開発から培ったノウハウと経験を活かし、高品質かつ業務に最適化したソリューションをご提供します。</p>
                  <div class="row">
                    <div class="col-md-12">
                      <ul class="list list-icons list-style-none pl-none mb-0 pb-0 pr-3">
                        <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="0" ><i class="fa fa-check text-primary text-4 mr-1"></i>オフショアコーディネータ</li>
                        <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="300" ><i class="fa fa-check text-primary text-4 mr-1"></i>オフショアパートナー認定支援</li>
                        <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="600" ><i class="fa fa-check text-primary text-4 mr-1"></i>オフショア推進・全般管理</li>
                        <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="900" ><i class="fa fa-check text-primary text-4 mr-1"></i>オンサイト作業支援</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <hr class="solid my-4">

            <div class="row align-items-start">
              <!--
                              <div class="col-lg-6">
                                  <div class="pr-5">
                                      <span class="d-block font-weight-light negative-ls-1 text-5 mb-1"><em class="text-2 opacity-8">Entertainment business</em></span>
                                      <h2 class="font-weight-extra-bold text-color-dark negative-ls-3 line-height-4 text-5 mb-3"><em>エンターテイメント事業</em></h2>

                                      <div class="row">
                                          <div class="col-md-12">
                                              <ul class="list list-icons list-style-none pl-none mb-0 pb-0 pr-3">
                                                  <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="0" ><i class="fa fa-check text-primary text-4 mr-1"></i>ゲーム企画・デザイン・運営管理全般</li>
                                                  <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="300" ><i class="fa fa-check text-primary text-4 mr-1"></i>Unityによる3Dゲーム開発</li>
                                                  <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="600" ><i class="fa fa-check text-primary text-4 mr-1"></i>Webゲーム開発</li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-6">
                                  <div class="pr-5">
                                      <span class="d-block font-weight-light negative-ls-1 text-5 mb-1"><em class="text-2 opacity-8">Software package product business</em></span>
                                      <h2 class="font-weight-extra-bold text-color-dark negative-ls-3 line-height-4 text-5 mb-3"><em>ソフトウエアパッケージ製品事業</em></h2>

                                      <div class="row">
                                          <div class="col-md-12">
                                              <ul class="list list-icons list-style-none pl-none mb-0 pb-0 pr-3">
                                                  <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="0" ><i class="fa fa-check text-primary text-4 mr-1"></i>パッケージ製品のローカライズ化</li>
                                                  <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="300" ><i class="fa fa-check text-primary text-4 mr-1"></i>パッケージ製品販売</li>
                                                  <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="600" ><i class="fa fa-check text-primary text-4 mr-1"></i>パッケージ製品開発</li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>-->

              <hr class="solid my-4">

              <div class="row align-items-start">
                <!--
                                <div class="col-lg-6">
                                    <div class="pr-5">
                                        <span class="d-block font-weight-light negative-ls-1 text-5 mb-1"><em class="text-2 opacity-8">Electronic Commerce business</em></span>
                                        <h2 class="font-weight-extra-bold text-color-dark negative-ls-3 line-height-4 text-5 mb-3"><em>EC 事業</em></h2>
                                        <p class="lead mb-2 pb-2 text-3 text-indent-05">自社ブランド「hanaro」のWEBサイトやオンラインストアの運営・管理、プロモーションの企画・立ち上げ</p>
                                        <div class="row">
                                            <div class="col-md-12">
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                <!--
                                <div class="col-lg-6">
                                    <div class="pr-5">
                                        <span class="d-block font-weight-light negative-ls-1 text-5 mb-1"><em class="text-2 opacity-8">Systems engineering services</em></span>
                                        <h2 class="font-weight-extra-bold text-color-dark negative-ls-3 line-height-4 text-5 mb-3"><em>IT 技術者支援人材派遣業務</em></h2>
                                        <p class="lead mb-2 pb-2 text-3 text-indent-05">お客様のご希望に応じシステム開発の多様なフェーズに対応し、必要な期間、必要なIT人材をご提案致します。一般労働者派遣事業新規申請受理済　平成31年2月取得予定</p>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <ul class="list list-icons list-style-none pl-none mb-0 pb-0 pr-3">
                                                    <li class="appear-animation text-2 text-color-primary mb-3" data-appear-animation="fadeInUp" data-appear-animation-delay="0" ><i class="fa fa-check text-primary text-4 mr-1"></i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>


    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "business",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>