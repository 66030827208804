<template>
  <div id="profile" class="body">
    <Top></Top>


    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>会社概要</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index.html">Home</router-link></li>
                <li class="active">会社案内</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="container pb-1">
        <div class="row">
          <div class="col-lg-3 order-2 order-lg-1 mt-4 mt-lg-0 pt-2">
            <aside class="sidebar">
              <h5 class="font-weight-bold">Categories</h5>
              <ul class="nav nav-list flex-column mb-0">
                <li class="nav-item">
                  <router-link class="nav-link active" to="#">会社案内</router-link>
                  <ul>
                    <li class="nav-item"><router-link class="nav-link " to="/message">代表挨拶</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/philosophy">経営理念</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/history">会社沿革</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/business">事業案内</router-link></li>
                    <li class="nav-item"><router-link class="nav-link active " to="/profile">会社概要</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/technology">技術分野</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/access">アクセス</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/privacy">個人情報保護方針</router-link></li>
                  </ul>
                </li>
                <li class="nav-item"></li>
              </ul>
            </aside>
          </div>
          <div class="col-lg-9 order-1 order-lg-2">
            <div class="row">
              <div class="col">
                <div class="overflow-hidden mb-3">
                  <!--<h2 class="text-color-dark font-weight-normal text-7 mb-0 appear-animation" data-appear-animation="fadeIn"  data-appear-animation-delay="100">Company <strong class="font-weight-extra-bold">Overview</strong></h2>-->
                  <h2 class="text-color-dark font-weight-normal text-8 mb-0 appear-animation text-font-en" data-appear-animation="fadeIn"  data-appear-animation-delay="100">Company Profile</h2>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="overflow-hidden mb-4">

                  <table class="table table-striped table-bordered appear-animation" data-appear-animation="fadeIn"  data-appear-animation-delay="100">
                    <tbody>
                    <tr>
                      <th class="w-25" scope="row">社名</th>
                      <td>InnoSource株式会社</td>
                    </tr>
                    <tr>
                      <th scope="row">住所・電話</th>
                      <td><router-link to="/access"><i class="far fa-caret-square-right fa-lg mr-1"></i>事務所</router-link><br>
                        　〒850－0035<br>
                        　長崎県長崎市元船町9-18 2階<br>
                        　Email:info@innosource.co.jp<p></p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">代表者</th>
                      <td>代表取締役　顧　楽諾</td>
                    </tr>
                    <tr>
                      <th scope="row">設立</th>
                      <td>令和5年7月7日</td>
                    </tr>
                    <tr>
                      <th scope="row">資本金</th>
                      <td>資本金1250万円 </td>
                    </tr>
                    <tr>
                      <th scope="row">取引銀行</th>
                      <td>
                        <!-- みずほ銀行<br> -->
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">従業員数</th>
                      <td>１０名</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>



    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "profile",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>