<template>
  <div id="philosophy" class="body">
    <Top></Top>
    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>経営理念</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index">Home</router-link></li>
                <li class="active">会社案内</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="container pb-1">
        <div class="row mt-3 mb-5">
          <div class="col-lg-12 appear-animation"  data-appear-animation="fadeIn" data-appear-animation-delay="400">
            <div class="row">
              <div class="col-sm-10 col-md-7 col-lg-12 mb-lg-0">
                <div class="overflow-hidden mb-2">
                  <!--<p class="mb-0 gradient-text-color text-5 " >経営理念</p>-->
                  <p class="mb-3 text-8 text-black text-font-en">Philosophy</p>
                  <!--<hr class="custom-divider mt-3 mb-0">-->
                </div>
                <div class="mb-3">
                  <ul class="list list-ordened text-color-dark">
                    <li><span>初心忘れるべからず</span>
                      <p class="text-color-dark">常に謙虚な気持ちを忘れることなく、チャレンジャーとして挑戦していきます。<br>「一番良い」ではなく「より良い」製品、サービスを提供し続けることに取り組んでいきます。</p></li>
                    <li><span>社員を大切に</span>
                      <p class="text-color-dark">最前線でお客様と接している社員を支え、能力を発揮するために必要な権限とサポートを与えこと重点に考えています。<br>また、社員が豊かな生活を実現できるように環境整備します。<br>これらの実現することが「より良いサービス」を長くお客様に提供できることに繋がるはずです。</p></li>
                    <li><span>より良い会社、社会へ</span>
                      <p class="text-color-dark">企業活動を通じて豊かな社会づくりに貢献し、社会とともに成長していく企業であり続けるように努めていきます。</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "philosophy",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>