<template>
  <div id="service" class="body">
    <Top></Top>


    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>サービス</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index">Home</router-link></li>
                <li class="active">サービス</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="product_list p-0">

        <div class="pt-2 pb-5" style="width: 1080px;margin: 0 auto;">
          <div class="row mb-lg-6">
            <div class="col-lg-6 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
              <div class="feature-box feature-box-style-2">
                <div class="feature-box-icon">
                  <i class="fas fa-caret-square-right"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold mb-2">システム開発</h4>
                  <p>最先端の技術力を持つ技術者集団で、高い機動力とマネジメント力によって、お客様にとってより満足度の高いシステムをご提供しています。</p>
                  <ul class="list list-icons">
                  </ul>

                </div>
              </div>
            </div>

            <div class="col-lg-6 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
              <div class="feature-box feature-box-style-2">
                <div class="feature-box-icon">
                  <i class="fas fa-caret-square-right"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold mb-2">システム運用保守</h4>
                  <p>主に金融・流通・物流関係の業種で実績を積み重ねてきましたが、特に証券・金融業界を得意としており、豊富な実績を誇ります。</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 appear-animation" data-appear-animation="fadeInUpShorter">
              <div class="feature-box feature-box-style-2">
                <div class="feature-box-icon">
                  <i class="fas fa-caret-square-right"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold mb-2">ICT活用のBPO支援</h4>
                  <p>お客様の業務に沿って最適な業務フローを設計し、「システム」と「BPO」業務をセットで提供することにより、お客様の業務効率化、ミス軽減のための支援をします。</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
              <div class="feature-box feature-box-style-2">
                <div class="feature-box-icon">
                  <i class="fas fa-caret-square-right"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold mb-2">中国ソリューション</h4>
                  <p>中国の優れたITソリューションを発掘し、現地企業とコラボレーションすることで、日本市場にサービスを提供します。</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

    </div>


    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "service",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>