<template>
  <header id="header" data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': false, 'stickyStartAt': 0}">

    <div class="header-body">
      <div class="header-container container">
        <div class="header-row">
          <div class="header-column">
            <div class="header-row">
              <div class="header-logo">
                <router-link to="index"><img alt="RS" width="80" height="80" data-sticky-width="100" data-sticky-height="40" src="vfront/img/logo.png" class="mb-4"></router-link>
              </div>
            </div>
          </div>
          <div class="header-column justify-content-end">
            <div class="header-row">
              <div class="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border order-2 order-lg-1">
                <div class="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
                  <nav class="collapse">
                    <ul class="nav nav-pills" id="mainNav">
                      <li><router-link class="" to="index">Home</router-link></li>
                      <li class="dropdown">
                        <router-link class="dropdown-item dropdown-toggle" to="profile">
                          会社案内
                        </router-link>
                        <ul class="dropdown-menu">
                          <li><router-link class="dropdown-item" to="/message">代表挨拶</router-link></li>
                          <li><router-link class="dropdown-item" to="/philosophy">経営理念</router-link></li>
                          <li><router-link class="dropdown-item" to="/history">会社沿革</router-link></li>
                          <li><router-link class="dropdown-item" to="/business">事業案内</router-link></li>
                          <li><router-link class="dropdown-item" to="/profile">会社概要</router-link></li>
                          <li><router-link class="dropdown-item" to="/technology">技術分野</router-link></li>
                          <li><router-link class="dropdown-item" to="/access">アクセス</router-link></li>
                          <li><router-link class="dropdown-item" to="/privacy">個人情報保護方針</router-link></li>
                        </ul>
                      </li>
                      <li><router-link class="" to="/service">サービス</router-link></li>
                      <li><router-link class="" to="/recruit">採用情報</router-link></li>
                      <li><router-link class="" to="/partner">パートナー様へ</router-link></li>
                      <li><router-link class="" to="/inquiries">お問合わせ</router-link></li>
                    </ul>
                  </nav>
                </div>
                <button class="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav" style="background:#6495ED">
                  <i class="fas fa-bars"></i>
                </button>
              </div>
              <div class="header-nav-features order-1 order-lg-2">
                <div class="header-nav-feature header-nav-features-social-icons d-inline-flex">
                  <ul class="header-social-icons social-icons d-none d-sm-block social-icons-clean ml-0">

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
name: "top"
}
</script>

<style scoped>

</style>