<template>
  <footer id="footer" class="border-0 mt-0">
    <div class="container py-4">
      <div class="row justify-content-md-center py-5 mt-3">
        <div class="col-md-12 col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-start mb-5 mb-lg-0">
          <router-link to="index"><img width="80" src="/vfront/img/logo.png" alt="Logo" class="img-fluid" style="max-width: 110px;"></router-link>
        </div>
        <div class="col-md-6 col-lg-2 text-center text-lg-left mb-0 mb-lg-0">
          <h5 class="text-5 text-transform-none font-weight-semibold text-color-light mb-4">Page</h5>
          <ul class="list list-unstyled">
            <li class="mb-1"><router-link to="/index" class="link-hover-style-1"> Home</router-link></li>
            <li class="mb-1"><router-link to="/service" class="link-hover-style-1"> サービス</router-link></li>
            <li class="mb-1"><router-link to="/access" class="link-hover-style-1">アクセス</router-link></li>
            <li class="mb-1"><router-link to="/recruit" class="link-hover-style-1">採用情報</router-link></li>
            <li class="mb-1"><router-link to="/partner" class="link-hover-style-1">パートナー様へ</router-link></li>
            <li class="mb-1"><router-link to="/privacy" class="link-hover-style-1"> 個人情報保護方針</router-link></li>
            <li class="mb-1"><router-link to="/inquiries" class="link-hover-style-1"> お問合わせ</router-link></li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-2 text-center text-lg-left mb-0 mb-lg-0">
          <h5 class="text-5 text-transform-none font-weight-semibold text-color-light mb-4">　</h5>
          <ul class="list list-unstyled">
            <li class="mb-1"><router-link to="/message" class="link-hover-style-1">代表挨拶</router-link></li>
            <li class="mb-1"><router-link to="/philosophy" class="link-hover-style-1">経営理念</router-link></li>
            <li class="mb-1"><router-link to="/history" class="link-hover-style-1">会社沿革</router-link></li>
            <li class="mb-1"><router-link to="/business" class="link-hover-style-1">事業案内</router-link></li>
            <li class="mb-1"><router-link to="/profile" class="link-hover-style-1">会社概要</router-link></li>
            <li class="mb-1"><router-link to="/technology" class="link-hover-style-1">技術分野</router-link></li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-2 text-center text-lg-left">

          <h5 class="text-5 text-transform-none font-weight-semibold text-color-light mb-4">Other</h5>
          <p class="mt-3 mb-0 text-center text-lg-left">
            <i class="fab fa-whatsapp text-color-primary"></i><span class="text-color-light opacity-7 pl-2">info@innosource.co.jp</span>

          </p>
        </div>
      </div>
    </div>
    <div class="footer-copyright footer-copyright-style-2 background-transparent footer-top-light-border">
      <div class="container py-2">
        <div class="row py-4">
          <div class="col d-flex align-items-center justify-content-center mb-4 mb-lg-0">
            <p>© Copyright 2023. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
name: "foot"
}
</script>

<style scoped>

</style>