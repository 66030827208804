<template>
<!--  <keep-alive>-->
<!--    <router-view/>-->
<!--  </keep-alive>-->
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>

export default {
  name: "app",
  components: {
  },
}
</script>

<style>
.main{
  padding-top: 116px;
}
</style>
