<template>
  <div id="inquiries" class="body">
    <Top></Top>

    <div role="main" class="main">
      <section class="page-header page-header-classic page-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 data-title-border>お問合わせ</h1>
            </div>
            <div class="col-md-4 order-1 order-md-2 align-self-center">
              <ul class="breadcrumb d-block text-md-right">
                <li><router-link to="index.html">Home</router-link></li>
                <li class="active">お問合わせ</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="container py-4">

        <section class="section section-default mt-0 mb-0">

          <div class="container">
            <div class="block-area row appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="300">
              <ul class="list list-icons list-icons-style-3 list-primary">
                <li class="mb-0"><i class="far fa-envelope"></i><h4 class="mb-0">お問い合わせ</h4></li>
              </ul>
              <p class="mx-auto mb-3">以下の「<span class="font-weight-bold">個人情報の取り扱い</span>」をご確認頂いたうえお問い合わせください。</p>
              <div class="consent col-lg-12">


                    <pre>

【お問合せ窓口】
イノソース株式会社 　個人情報問合せ窓口
〒850-0035長崎県長崎市元船町9-18 2階
メールアドレス：<span class="font-weight-bold text-font-en text-4"><router-link to="mailto:info@innosource.co.jp">info@innosource.co.jp</router-link></span>
※ 土・日曜日、祝日、年末年始、ゴールデンウィーク期間、
夏季休暇期間は	翌営業日以降の対応とさせていただきます。


                    </pre>
              </div>
            </div>
          </div>

        </section>

        <div class="row mb-4">
          <div class="col-sm-1 col-md-0 col-lg-12 mb-lg-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="400">
            <div class="featured-boxes featured-boxes-modern-style-5 featured-boxes-modern-style-primary">
              <div class="featured-box">
                <div class="box-content px-lg-1 px-xl-1 py-lg-1 text-color-light">
                  <i class="far fa-user-chart"></i>
                  <h2>個人情報取り扱い</h2>
                  <p class="font-weight-light text-left text-indent-1 text-color-light">
                    お問合せにおけるお客様の個人情報につきましては、イノソース株式会社にて、下記の通り、お取り扱いいたします。<br>

                    ＜情報の利用目的＞<br>
                    ●お問合せへの対応<br>
                    ＜情報の保管＞<br>
                    ●お客様の個人情報は、適切に管理し、当社が必要と判断する期間保管させていただきます。<br>
                    ＜情報の提供・開示＞<br>
                    次の場合を除き、お客様の同意なく個人情報を第三者に提供または開示することはありません。<br>
                    上記利用目的のために、協力会社に業務委託する場合。<br>
                    ●当該協力会社に対しては、適切な管理と利用目的外の使用をさせない措置をとります。<br>
                    ●法令に基づいて、司法、行政またはこれに類する機関から情報開示の要請を受けた場合。<br>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-3 order-2 order-lg-1 mt-4 mt-lg-0 pt-2  appear-animation "  data-appear-animation="fadeIn" data-appear-animation-delay="400" >
            <aside class="sidebar">
              <h5 class="font-weight-bold">Categories</h5>
              <ul class="nav nav-list flex-column mb-0">
                <li class="nav-item">
                  <router-link class="nav-link active" to="#">会社案内</router-link>
                  <ul>
                    <li class="nav-item"><router-link class="nav-link " to="/message">代表挨拶</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/philosophy">経営理念</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/history">会社沿革</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/business">事業案内</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/profile">会社概要</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/technology">技術分野</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/access">アクセス</router-link></li>
                    <li class="nav-item"><router-link class="nav-link " to="/privacy">個人情報保護方針</router-link></li>
                  </ul>
                </li>
                <li class="nav-item"></li>
              </ul>

            </aside>
          </div>

          <div class="col-lg-0 offset-lg-1 order-1 order-lg-2 ml-0 mb-4  appear-animation "  data-appear-animation="fadeIn" data-appear-animation-delay="400" >

            <h4 class="pt-0"><strong>事務所</strong></h4>
            <ul class="list list-icons list-icons-style-3 mt-2">
              <li><i class="fas fa-map-marker-alt top-6"></i> <strong>住所:</strong> <br>
                〒850-0035長崎県長崎市元船町9-18 2階</li>
                <li><i class="fas fa-subway top-6"></i> <strong>電車:</strong><br>
                ・長崎電軌本線 / 五島町駅 徒歩3分　<br>
                ・長崎電軌本線 / 大波止駅 徒歩4分<br>
                ・西九州新幹線 / 長崎駅 徒歩10分<br></li>
              <li><i class="fas fa-phone top-6"></i> <strong>Email:</strong><br>
                info@innosource.co.jp<br>
                <i class="far fa-clock top-6"></i>&nbsp;受付時間：平日10:00～12:00 13:00～17:30<br>
                （土日、祝祭日、年末年始、弊社が定める定休日を除く）
              </li>
            </ul>

          </div>
        </div>

      </div>

      <div id="googlemaps-all" class="google-map m-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="300" style="height:450px;"></div>

    </div>



    <Foot></Foot>
  </div>
</template>

<script>
import Top from "@/components/top";
import Foot from "@/components/foot";

export default {
  name: "inquiries",
  components: {
    Top,
    Foot
  },
}
</script>

<style scoped>

</style>